@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&family=Inter+Tight&display=swap');
@import url('https://fonts.cdnfonts.com/css/sf-ui-text-2');
                
@tailwind base;
@tailwind components;
@tailwind utilities;

*:not(.Timeline_scroller)::-webkit-scrollbar {
  display: none;
}
*{
  box-sizing: border-box;
}

@font-face {
  font-family: 'Blender Pro';
  src: url('/public/fonts/Blender-Pro-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: 'SF UI Text', 'CircularStd', 'Arial', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  width: 100%;
  height: 100%;
}

html, body { scroll-behavior:smooth; }

#root{
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

video::-webkit-media-controls {
  display: none;
}

video::-webkit-media-controls-enclosure {
  display: none;
}

*::-webkit-media-controls-panel {
  display: none!important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::--webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */
*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

.hover-gradient-button {
  width: 255px;
  height: 60px;

  &:hover::before {
    content: '';
    z-index: -1;
    position: absolute;
    background: radial-gradient(rgba(255, 202, 69, 1) 0%, rgba(188, 117, 249, 1) 20%, rgba(50, 65, 255, 1) 45%);
    animation: rotation 6s cubic-bezier(0.94, 0.49, 0.28, 0.33) infinite;
    transform-origin: 250px 330px;
    bottom: -360px;
    right: -330px;
    border-radius: 999px;
    width: 720px;
    height: 720px;
  }

  &:hover {
    position: relative;
    overflow: hidden;
    border-radius: 30px;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
